@font-face {
  font-family: 'Gotham';
  src: url('../../fonts/gotham/gotham-book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../../fonts/gotham/gotham-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../../fonts/gotham/gotham-bold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../../fonts/gotham/gotham-black.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
